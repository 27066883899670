/* Pagy */
.pagy-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 12px;
  line-height: 1.25rem;
  color: rgb(107 114 128);
  border: none;
}

.pagy-nav > :not([hidden]) ~ :not([hidden]) {
  --space-reverse: 0;
  margin-right: calc(0.25rem * var(--space-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-reverse)));
}

.pagy-nav .page.gap {
  gap: 4px;
}

.pagy-nav .page a {
  display: block;
  text-decoration: none;
}

.pagy-nav .page a:hover {
  color: blue;
  border-top: 1px solid blue;
}

.pagy-nav .page.active {
  cursor: default;
  border-top: 2px solid blue;
  color: blue;
}

.pagy-nav .page.disabled a {
  cursor: default;
  color: rgb(209 213 219)
}

.pagy-nav .pagy-combo-input, .pagy-nav.pagy-items-selector-js {
  display: inline-block;
  border-radius: 0.5rem;
}

.pagy-nav .pagy-combo-input input, .pagy-nav.pagy-items-selector-js input {
  border-radius: 0.375rem;
  border-style: none;
  background-color: rgb(243 244 246);
}

/* Ckeditor custom styles */
body .ck.ck-content h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

body .ck.ck-content h2 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

body .ck.ck-content h3 {
  font-size: 2rem;
  margin-bottom: 0.8rem;
}

body .ck.ck-content h4 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

body .ck.ck-content a span {
  padding: 8px;
  line-height: 1.8;
}

body .ck.ck-content img {
  border-radius: 4px;
}

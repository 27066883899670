@import "chat";
@import "cropper";
@import "ckeditor";
@import "ckeditor5/dist/ckeditor5";
@import "default_template";
@import "pagy";
@import "sign_in";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Pickr */
.pickr {
  border: 1px solid rgba(100, 100, 100, 0.5);
  border-radius: 0.375rem;
  padding: 2px;
}

/* Sortable */
.sortable-ghost .page {
  background-color: aliceblue;
}

@media (max-width: 1023px) {
  .column {
    top: auto !important;
  }
  .column.content {
    height: fit-content !important;
  }
  .row-container {
    height: fit-content !important;
  }
}


/**
 *
 * Default template
 *
**/
@font-face {
  font-family: "Raleway";
  src: font-url("Raleway/Raleway-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: font-url("Lato/Lato-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: font-url("Roboto/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: font-url("Poppins/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: font-url("Open_Sans/OpenSans-VariableFont_wdth,wght.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: font-url("Montserrat/Montserrat-VariableFont_wght.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Theme: default */
body {
  background-color: var(--base-color, inherit);
}

body h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color, #000);
}

body main a {
  color: var(--link-color, rgb(37,99,235));
}

input[type='submit']:disabled {
  background-color: rgba(100, 100, 100, 0.5);
}

.event-ics {
  font-size: .75rem;
  line-height: 1rem;
  padding-bottom: .25rem;
  padding-top: .25rem;
  padding-left: .5rem;
  padding-right: .5rem;
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128/var(--tw-border-opacity));
  border-width: 1px;
  border-radius: .375rem;
}

#messages.guardian-logged-in .guardian {
  flex-direction: row-reverse;
  margin-right: 10px;
  .content {
    color: white;
    background-color: #007bff;
  }
}

#messages.staff-logged-in .staff,
#messages.staff-logged-in .accountadmin {
  margin-right: 10px;
  flex-direction: row-reverse;
}

#messages.staff-logged-in .staff .content {
  color: white;
  background-color: #007bff;
}
